import React, {useEffect, useState} from 'react'
import {Card} from '@material-ui/core'
import db from '../firebase.js'


export const Ws1UI = () => {


    let [orderType, setOrderType] = useState([])

    useEffect(() => {
        db.collection("Order").orderBy("Time", "desc").limit(1)
            .onSnapshot(snapshot =>
                setOrderType(snapshot.docs[0].data().Type)
            )


    }, [])


    let [ws1, setWs1] = useState({})
    useEffect(() => {
        db.collection("Realtime").doc("WS 1").onSnapshot(doc => {
            setWs1(doc.data())


        })
    }, [])

    let [order, setOrder] = useState({Received: false, Type: ""});
    useEffect(() => {
        db.collection("Order").doc("Status").onSnapshot(doc => {
            setOrder(doc.data())
        })
    }, [])

    return (
        <div>
            <div className="bottom">
                <div className="cardItem" style={{marginRight: "10px"}}>
                    <Card>

                        <p className="title" style={{color: "#ef5350"}}>User</p>
                        <p className="value">{ws1.user}</p>
                    </Card>
                </div>
                {/*<div className="cardItem">
          <Card>
            <p className="title" style={{ color: "#66bb6a" }}>Inventory</p>
            <p className="value">{ws1.inventory}</p>
              <p className="title" style={{ color: "#66bb6a" }}>Inventory</p>
              <div className="inventoryRow">
                  <p className="value">{ws1.inventoryBlue}</p>
                  <div style={{height:"2px",width:"2px", backgroundColor:"blue"}}></div>
              </div>
              <div className="inventoryRow">
                  <p className="value">{ws1.inventoryGreen}</p>
                  <div style={{height:"2px",width:"2px", backgroundColor:"green"}}></div>
              </div>
              <div className="inventoryRow">
                  <p className="value">{ws1.inventoryOrange}</p>
                  <div style={{height:"2px",width:"2px", backgroundColor:"orange"}}></div>
              </div>
              <div className="inventoryRow">
                  <p className="value">{ws1.inventoryPink}</p>
                  <div style={{height:"2px",width:"2px", backgroundColor:"pink"}}></div>
              </div>
              <div className="inventoryRow">
                  <p className="value">{ws1.inventoryRed}</p>
                  <div style={{height:"2px",width:"2px", backgroundColor:"red"}}></div>
              </div>
          </Card>
        </div>*/}
                <div className="cardItem" style={{marginRight: "10px"}}>
                    <Card>
                        <p className="title" style={{color: "#ffa726"}}>Part No.</p>
                        <p className="value">{ws1.powerStatus === true ? orderType : "N/A"}</p>
                        {/* <p className="value">{orderType=="5/2" || orderType=="3/2"?orderType:"N/A"}</p> */}
                    </Card>
                </div>
                <div className="cardItem" style={{marginRight: "10px"}}>
                    <Card>
                        <p className="title" style={{color: " #26c6da"}}>Pressure</p>
                        <p className="value">{ws1.stnPressure === true ? "100%" : "0%"}</p>
                    </Card>
                </div>
                <div className="cardItem" style={{marginRight: "10px"}}>
                    <Card style={{
                        border: "2px solid blue",
                        backgroundColor: `${order.Type === "type_1" ? "blue" : "white"}`
                    }}>

                        <p className="title" style={{color: `${order.Type === "type_1" ? "white" : "blue"}`}}>Blue</p>
                        <p className="value" style={{
                            fontWeight: "bold",
                            color: `${order.Type === "type_1" ? "white" : "blue"}`
                        }}>{ws1.inventoryBlue}</p>
                    </Card>
                </div>
                <div className="cardItem" style={{marginRight: "10px"}}>
                    <Card style={{
                        border: "2px solid green",
                        backgroundColor: `${order.Type === "type_4" ? "green" : "white"}`
                    }}>

                        <p className="title" style={{color: `${order.Type === "type_4" ? "white" : "green"}`}}>Green</p>
                        <p className="value" style={{
                            fontWeight: "bold",
                            color: `${order.Type === "type_4" ? "white" : "green"}`
                        }}>{ws1.inventoryGreen}</p>
                    </Card>
                </div>
                <div className="cardItem" style={{marginRight: "10px"}}>
                    <Card style={{
                        border: "2px solid orange",
                        backgroundColor: `${order.Type === "type_5" ? "orange" : "white"}`
                    }}>

                        <p className="title"
                           style={{color: `${order.Type === "type_5" ? "white" : "orange"}`}}>Orange</p>
                        <p className="value" style={{
                            fontWeight: "bold",
                            color: `${order.Type === "type_5" ? "white" : "orange"}`
                        }}>{ws1.inventoryOrange}</p>
                    </Card>
                </div>
                <div className="cardItem" style={{marginRight: "10px"}}>
                    <Card style={{
                        border: "2px solid hotpink",
                        backgroundColor: `${order.Type === "type_2" ? "hotpink" : "white"}`
                    }}>

                        <p className="title"
                           style={{color: `${order.Type === "type_2" ? "white" : "hotpink"}`}}>Pink</p>
                        <p className="value" style={{
                            fontWeight: "bold",
                            color: `${order.Type === "type_2" ? "white" : "hotpink"}`
                        }}>{ws1.inventoryPink}</p>
                    </Card>
                </div>
                <div className="cardItem">
                    <Card style={{
                        border: "2px solid red",
                        backgroundColor: `${order.Type === "type_3" ? "red" : "white"}`
                    }}>

                        <p className="title" style={{color: `${order.Type === "type_3" ? "white" : "red"}`}}>Red</p>
                        <p className="value" style={{
                            fontWeight: "bold",
                            color: `${order.Type === "type_3" ? "white" : "red"}`
                        }}>{ws1.inventoryRed}</p>
                    </Card>
                </div>

            </div>

            <div className="bottom" style={{marginBottom: "10px"}}>


            </div>
        </div>
    )
}
