import React from 'react'

export const OrderDetail = () => {

    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Intl.DateTimeFormat('en-US', options).format(Date.now());

// const d=new Date()

    return (
        <div className="order-details">
        {/* <p>Order #2666</p> */}
        <p>{date}</p>
        {/* <p>{d.getDay()+" "+d.getDate()+" "+d.getMonth()+" "+d.getFullYear()}</p> */}
        {/* <p>{Date.get()}</p> */}
      </div>
    )
}
