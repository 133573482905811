import React, { useEffect, useState } from "react";
import "./css/WSSecondRow.css";
import { Route, Switch } from "react-router-dom";
import { Workstation1 } from "./Workstation1";
import { Workstation2 } from "./Workstation2";
import { Workstation3 } from "./Workstation3";
import { WSProgressBars } from "./WSProgressBars";
import { Col, Row } from "react-bootstrap";
import db from "../firebase.js";
import { StreamDialog } from "./StreamDialog";

export const WSSecondRow = () => {
  const [currentStreamIndex, setCurrentStreamIndex] = useState(-1);
  const [showDialog, setshowDialog] = React.useState(false);
  // const [streamData, setStreamData] = useState([
  //   {
  //     isStreamUp: false,
  //     machineName: "",
  //     streamUrl: "",
  //   },
  // ]);

  const [stream1] = React.useState({
    stream: "https://workstation.fsmiitd.in/ws1",
    title: "Raw Part Storage Loading & QC Workstation",
  });
  const [stream2] = React.useState({
    stream: "https://workstation.fsmiitd.in/ws2",
    title: "Axisymmetric Part Storage QC & Inspection Workstation",
  });
  const [stream3] = React.useState({
    stream: "https://workstation.fsmiitd.in/ws3",
    title: "Prismatic Part Assly & Screwing Workstation",
  });

  useEffect(() => {
    db.collection("StreamStatus")
      .doc("currentStream")
      .onSnapshot((doc) => {
        setCurrentStreamIndex(Number.parseInt(doc.data()["index"]));
      });
    // console.log("Current Stream Index : ",currentStreamIndex);
  }, []);

  // useEffect(() => {
  //   socket.on("stream_1", function (data) {
  //     const value = "data:image/jpeg;base64," + data.data;
  //     setStream1({ stream: value, title: data.title });
  //   });
  //   socket.on("stream_2", function (data) {
  //     const value = "data:image/jpeg;base64," + data.data;
  //     setStream2({ stream: value, title: data.title });
  //   });
  //   socket.on("stream_3", function (data) {
  //     const value = "data:image/jpeg;base64," + data.data;
  //     setStream3({ stream: value, title: data.title });
  //   });
  // }, []);

  // useEffect(() => {
  //   // return db.collection("Stream").doc("LiveView").onSnapshot((doc) => {
  //   //     setStreamData(doc.data());
  //   // })
  //   return db.collection("Stream").onSnapshot((querySnapshot) => {
  //     setStreamData([]);
  //     const tempList = [];
  //     querySnapshot.forEach((doc) => {
  //       // console.log(doc.data());
  //       tempList.push(doc.data());
  //     });
  //     console.log("Temp List", tempList);
  //     setStreamData(tempList);
  //     console.log("Stream List", streamData);
  //     console.log("1", streamData.length > 0);
  //   });
  // }, []);

  function getStream(index) {
    switch (index) {
      case 1:
        return stream1;
      case 2:
        return stream2;
      case 3:
        return stream3;
      default:
        return stream1;
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <Row sm={"auto"} style={{ margin: "10px " }}>
        <Col lg="6" xl="6" xxl="6" style={{ paddingBottom: "10px" }}>
          <Switch>
            <Route exact path="/dashboard" component={Workstation1} />
            <Route exact path="/dashboard/ws2" component={Workstation2} />
            <Route exact path="/dashboard/ws3" component={Workstation3} />
          </Switch>
        </Col>
        <Col lg="6" xl="6" xxl="6">
          <WSProgressBars />
          {/*{streamData.length>0 &&*/}
          {/*    streamData.map(item => (item.isStreamUp&&*/}
          {/*        <div className={"stream-card"}>*/}
          {/*            <img className={"stream-img"} src={item.streamUrl}/>*/}
          {/*            <div style={{marginTop: "10px"}}>{streamData[3].machineName}</div>*/}
          {/*        </div>*/}
          {/*    ))}*/}
          {currentStreamIndex !== -1 && (
            <div className={"stream-card"} onClick={() => setshowDialog(true)}>
              <img
                className={"stream-img"}
                src={getStream(currentStreamIndex + 1).stream}
                alt={""}
              />
              {/* <iframe
                className={"stream-img"}
                onClick={() => setshowDialog(true)}
                scrolling={"no"}
                src={streamData[currentStreamIndex].streamUrl}
              ></iframe> */}
              <div style={{ marginTop: "10px" }}>
                {getStream(currentStreamIndex + 1).title}
              </div>
            </div>
          )}
        </Col>
      </Row>
      {currentStreamIndex !== -1 && (
        <StreamDialog
          show={showDialog}
          data={getStream(currentStreamIndex + 1)}
          onHide={() => setshowDialog(false)}
        />
      )}
    </div>
  );
};
