import React from "react";
import {Card, Col, Row} from "react-bootstrap";
import wsimage from "../assets/img/wsimage.png"

function WorkstationUtils() {
  return <Row style={{justifyContent:"space-evenly",padding:"0 30px"}}>
    <Col >
      <Card style={{borderRadius:"20px",boxShadow:"-5px 5px 10px grey"}}>
        <Card.Img variant="top" src={wsimage} style={{objectFit:"contain",maxHeight:"400px",minHeight:"200px"}}  />
        <div style={{margin:"20px auto", }}>
          <div className="box">A</div>
          <div className="box">B</div>
          <div className="box">C</div>
          <div className="box">D</div>
        </div>
      </Card>
    </Col>


  </Row>;
}

export default WorkstationUtils;
