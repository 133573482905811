import React from 'react'
import './css/WSSecondRow.css'
import {Card} from '@material-ui/core'

import {OrderPB} from './OrderPB'
import {OrderDetail} from './OrderDetail'

export const WSProgressBars = () => {

    return (
        <div className="secondRow-right" >
            <Card>
                <div className="pb-container" style={{display:"grid",}}>

                    <OrderPB/>
                    {/* <Ws1PB/>

                    <Ws2PB/>
                    <Ws3PB/> */}
                </div>
            </Card>

            <OrderDetail/>
        </div>
    )
}
