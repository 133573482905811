import firebase from 'firebase'

  
// Your web app's Firebase configuration
//   var firebaseConfig = {
//     apiKey: "GV9Jm2u7rmsCe65wKzPTw5jtS38n2tVEGi5T8qo",
//     authDomain: "realtimedashboard-53e96.firebaseapp.com",
//     projectId: "realtimedashboard-53e96",
//     storageBucket: "realtimedashboard-53e96.appspot.com",
//     messagingSenderId: "1037949123340",
//     appId: "1:1037949123340:web:867fc1b1816d8a605de729"
//   };
var firebaseConfig = {
  apiKey: "AIzaSyCAtD_LiEqzgM8sWQBgNFeMUDT-xTTklMw",
  authDomain: "fsmdashboard-1f0d5.firebaseapp.com",
  projectId: "fsmdashboard-1f0d5",
  storageBucket: "fsmdashboard-1f0d5.appspot.com",
  messagingSenderId: "646361906649",
  appId: "1:646361906649:web:581ba1a3c0ce7f08dbadc8",
  measurementId: "G-3S47P44ZXM",


 //  apiKey: "AIzaSyAuUHIhpFNEzqp-e3pOPq901ImasERTNdw",
   //authDomain: "fsmdashboardtest.firebaseapp.com",
 databaseURL: "https://fsmdashboard-1f0d5-default-rtdb.firebaseio.com/",
  //projectId: "fsmdashboardtest",
   //storageBucket: "fsmdashboardtest.appspot.com",
   //messagingSenderId: "686384032480",
 // appId: "1:686384032480:web:ee283b18254f5cc5defe85",
   //measurementId: "G-M5ML7BED0Q"
 
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

var db=firebase.firestore()

export default db;
