import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import './css/Layout.css'
import {Link, Route, Switch, useHistory} from "react-router-dom"
// import { Charts } from './Charts'
// import { DashboardPage } from './DashboardPage'
// import CalendarPage from './CalendarPage'
import {Workstations} from './Workstations'
import {NotificationsPage} from './NotificationsPage'
import {UserProfile} from './UserProfile'
import {Analytics} from './Analytics'
import './css/LogOutBtn.css'
import {OrderHistory} from "./OrderHistory";
import {Col, Offcanvas, Row} from "react-bootstrap";
import {Dashboard, HistoryOutlined, MenuOutlined, Person} from "@material-ui/icons";
import {HeaderMain} from "./HeaderMain";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import logo from '../assets/img/alert.png'
import db from "../firebase";
import WorkstationUtils from "./WorkstationUtils";


const drawerWidth = "23%"

const useStyles = makeStyles({
    drawer: {
        width: drawerWidth,
    },

    drawerPaper: {
        width: drawerWidth,
        backgroundColor: "#343a40",
        color: "#fff",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;"
    },

    link: {
        textDecoration: "none",
        color: "#555"
    }


})
// const Main = styled('main')(
//     ({ theme, open }) => ({
//         flexGrow: 1,
//         padding: theme.spacing(3),
//         transition: theme.transitions.create('margin', {
//             easing: theme.transitions.easing.sharp,
//             duration: theme.transitions.duration.leavingScreen,
//         }),
//         marginLeft: "0",
//         ...(open && {
//             transition: theme.transitions.create('margin', {
//                 easing: theme.transitions.easing.easeOut,
//                 duration: theme.transitions.duration.enteringScreen,
//             }),
//             marginLeft: `${drawerWidth}`,
//
//         }),
//     }),
// );


function miClicked(id) {
    // console.log(id)
    document.getElementById("mi1").classList.remove("active");
    document.getElementById("mi2").classList.remove("active");
    document.getElementById("mi3").classList.remove("active");
    document.getElementById("mi4").classList.remove("active");
    // document.getElementById("mi3").classList.remove("active");


    let mi = document.getElementById(id)

    mi.classList.add("active");

}

export const Layout = () => {

    let history = useHistory()

    //  Workstation 1 progress bar 


    // let d = "das-selected"
    // let c = "charts-selected"
    // let cal = "calendar-selected"
    const classes = useStyles()

    // const docRef = doc(db, "Sensors", "WS1");

    const [show, setShow] = useState(false)
    const [hasError, setHasError] = useState(false)
    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    useEffect(() => {
        // return onSnapshot(docRef, (doc) => {
        //     setHasError(doc.data()["EntrySensor"])
        // });

       return db.collection("Sensors").doc("WS1")
            .onSnapshot((doc) => {
                console.log("Current data: ", doc.data()["EntrySensor"]);
                setHasError(doc.data()["EntrySensor"])
            });

    }, [])

    return (
        <div>
            <Row style={{marginBottom: "0"}}>
                {!show && <Col sm={"auto"}>
                    <div className={"close-drawer-btn"} onClick={() => {
                        setShow(true);
                    }}>
                        <MenuOutlined style={{color: "black", width: "100%", height: "100%"}}/>
                    </div>
                </Col>}
                <Col>
                    <HeaderMain/>
                </Col>
            </Row>
            {/*<Button onClick={async () => {*/}
            {/*    await db.collection("Stream").doc("LiveView").set({*/}
            {/*        machineName: "Los Angeles",*/}
            {/*        streamUrl: "CA",*/}
            {/*    })*/}
            {/*}}>Firebase</Button>*/}
            <Switch>
                <Route exact path='/dashboard' component={Workstations}/>
                <Route path='/notifications' component={NotificationsPage}/>
                <Route path='/userprofile' component={UserProfile}/>
                <Route path='/analytics' component={Analytics}/>
                <Route path='/history' component={OrderHistory}/>
                <Route path='/workstation-utils' component={WorkstationUtils}/>
            </Switch>
            <Dialog open={hasError} onClose={() => {
            }}>
                <div style={{width:"100%",textAlign:"center"}}><img style={{width:"300px"}} src={logo} alt=""/></div>
                <DialogTitle>
                    <div style={{fontSize: "50px", fontWeight: "bold", color: "red"}}>{"Alert"}</div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div>
                            <div style={{fontSize: "35px", fontWeight: "bold"}}>WS 1 : Entry Sensor not working.</div>

                        </div>
                    </DialogContentText>
                </DialogContent>

            </Dialog>

            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title style={{fontWeight: "bold"}}>IITD-AIA-FSM</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="menuWrapper">
                        <Link className={classes.link} to={'/dashboard'} onClick={() => miClicked("mi1")}>
                            <div className="menuItem dashboard active" id="mi1">

                                <div className="menuLeft"><Dashboard/></div>
                                {window.matchMedia("(min-width: 750px)").matches && <div className="menuRight">
                                    Workstations
                                </div>}
                            </div>
                        </Link>

                        <Link className={classes.link} to={'/userprofile'} onClick={() => miClicked("mi2")}>
                            <div className="menuItem" id="mi2">
                                <div className="menuLeft"><Person/></div>
                                {window.matchMedia("(min-width: 750px)").matches && <div className="menuRight">
                                    User Profile
                                </div>}
                            </div>
                        </Link>
                        <Link className={classes.link} to={'/history'} onClick={() => miClicked("mi3")}>
                            <div className="menuItem" id="mi3">
                                <div className="menuLeft"><HistoryOutlined/></div>
                                {window.matchMedia("(min-width: 750px)").matches && <div className="menuRight">
                                    Order History
                                </div>}
                            </div>
                        </Link>
                        {/*<Link className={classes.link} to={'/workstation-utils'} onClick={() => miClicked("mi4")}>*/}
                        {/*    <div className="menuItem" id="mi4">*/}
                        {/*        <div className="menuLeft"><HistoryOutlined/></div>*/}
                        {/*        {window.matchMedia("(min-width: 750px)").matches && <div className="menuRight">*/}
                        {/*            Workstation Utils*/}
                        {/*        </div>}*/}
                        {/*    </div>*/}
                        {/*</Link>*/}
                        <div className="menuItem" id={"log-out"} onClick={() => {
                            history.push('/')
                            localStorage.removeItem("currentUser")
                        }}>
                            <div className="menuLeft"><ExitToAppIcon/>
                            </div>
                            {window.matchMedia("(min-width: 750px)").matches && <div className="menuRight">
                                Log Out
                            </div>}
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}
